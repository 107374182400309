import React from "react";
import Layout from "../components/layout3";
import SEO from "../components/seo";
import StudentTable from "../pages/StudentTable";
import StudentTable2 from "../pages/StudentTable2";
import data from "../pages/studentdata.json";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <StudentTable2 data={data} />
    <StudentTable data={data} />
  </Layout>
);

export default IndexPage;
